import * as React from "react";
import Layout from "../../components/layout";
import { Col, Row } from "react-bootstrap";
import TopImage from "../../components/topImage";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <TopImage imageName="start" />

      <Row className="about">
        <Col>
          <h1 className="mb-3">Welcome to the Stutter Relief Foundation</h1>

          <p>
            We are a non-profit organization working on the topic of{" "}
            <Link to="/stutter-relief/stuttering">
              Stuttering (or Stammering)
            </Link>
            . Our mission is to have a scientific look at stuttering, its causes
            and its effects to develop effective ways of treatment. With these
            treatments, we want to help people who suffer from stuttering become
            fluent again.
          </p>

          <p>
            Since July 1st, 2022, we have a new home and we are now part of the
            "Wegener-Hänger-Abasco Foundation for Beauty Treatments". This is a
            perfect match, as the goals of our two organisations overlap - to
            help people with their specific difficulties and providing first
            class treatment.
          </p>

          <p>
            We don’t generally see stuttering as disease or disability.
            According to what we found out in our studies so far, most cases of
            stuttering don’t have physical or psychological causes. In fact,
            they can rather be seen as a trained or developed wrong way of
            speaking, therefore it is possible to train a right way again. Of
            course, there are some rare cases of stuttering that have medical
            causes which need different ways of treatment.
          </p>

          <h2 className="mt-5 mb-3">Do you stutter / stammer?</h2>
          <p>
            Did you come here because you are looking for a way to handle your
            stuttering and become fluent again? Thanks to generous donations, we
            are able to offer our{" "}
            <Link to="/stutter-relief/training">speech training for free</Link>.
            Please contact us at{" "}
            <a href="mailto: stutter-relief@beauty-foundation.com">
              stutter-relief@beauty-foundation.com
            </a>
            .
          </p>
          <p>
            <Link to="/stutter-relief/participate">
              <span className="fw-bold">We need your help!</span>
            </Link>{" "}
            The most important part of understanding stuttering is talking to
            people who stutter.
          </p>
          <p>
            Do you stutter? Do you want to help yourself and others to
            understand more about stuttering and become fluent again?{" "}
            <Link to="/stutter-relief/participate">
              There are many ways in which you can help us, and we would greatly
              appreciate your support!
            </Link>
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
